.grid-item {
  width: 100%;
  position: relative;
}
.grid-item:last-child .grid-item__movie-text {
  bottom: 30%;
}
@media screen and (max-width: 800px) {
  .grid-item:last-child .grid-item__movie-text {
    bottom: 17%;
 }
}
.grid-item__img {
  width: 100vw;
  height: auto;
}
@media screen and (max-width: 800px) {
  .grid-item__img {
    display: none;
 }
}
@media screen and (min-width: 800px) {
  .grid-item__mobile-img {
    display: none;
 }
}
@media screen and (max-width: 800px) {
  .grid-item__mobile-img {
    display: block;
    height: auto;
 }
}
.grid-item__movie-text {
  width: 100%;
  position: absolute;
  bottom: 32%;
  left: 5%;
  cursor: pointer;
  right: 5%;
  color: #fff;
  font-size: 1.4vw;
  text-shadow: 3px 3px 4px black;
  line-height: 25px;
}
@media screen and (max-width: 800px) {
  .grid-item__movie-text {
    font-size: 15px;
    line-height: 21px;
    bottom: 10%;
    right: 10%;
    left: 12%;
 }
}
.grid-item__title {
  font-size: 42px;
  line-height: 50px;
}
@media screen and (max-width: 600px) {
  .grid-item__title {
    font-size: 16px;
 }
}
.grid-item__speaker {
  font-size: 25px;
  line-height: 20px;
  font-weight: bold;
}
@media screen and (max-width: 600px) {
  .grid-item__speaker {
    font-size: 16px;
 }
}
.grid-item__speaker-profession {
  font-size: 25px;
  line-height: 0px;
}
@media screen and (max-width: 600px) {
  .grid-item__speaker-profession {
    font-size: 16px;
 }
}
.grid-item__overview {
  color: white;
}

.item-bonce{
  right: 50%;
  bottom: 22%;
}

@media screen and (max-width: 1000px) {
  .item-bonce{
    right: 50%;
    bottom: 5%;
  }
}