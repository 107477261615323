.img-logo{
    margin-top: 40%;
    margin-bottom: 8%;   
    width: 80%; 
}

@media screen and ( max-width:  500px) {
    .img-logo{
        width: 40%;
    }
}