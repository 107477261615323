.movie-item-page:hover .movie-item-page__back {
  visibility: visible;
  transition: opacity 0.62s, ease 1s;
  opacity: 1;
}
.movie-item-page__back {
  z-index: 99999999;
  cursor: pointer;
  font-size: 30px;
  color: #69a2b9;
  display: block;
  position: fixed;
  top: 40px;
  left: 40px;
}
@media screen and (max-width: 800px) {
  .movie-item-page__back {
    top: 40px;
    left: 10px;
 }
}
.movie-item-page__video-info {
  z-index: 999;
  position: fixed;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: #000 73;
  padding: 10px 20px;
}
.movie-item-page__container {
  display: grid;
  grid-template-columns: auto 375px;
  grid-template-rows: 100vh;
  background: black;
  background: -webkit-linear-gradient(top, black 0%, #2f3a3d 100%);
  background: linear-gradient(to bottom, black 0%, #2f3a3d 100%);
}
@media screen and (max-width: 800px) {
  .movie-item-page__container {
    grid-template-columns: 100vw;
    grid-template-rows: auto;
 }
}
.movie-item-page__video {
  width: 100%;
}
@media screen and (max-width: 800px) {
  .movie-item-page__video {
    width: 100vw !important;
    position: initial;
    transform: none;
 }
}
.movie-item-page__videoWithoutChat {
  width: 100vw !important;
  height: 100% !important;
}
@media screen and (max-width: 800px) {
  .movie-item-page__videoWithoutChat {
    width: 100vw !important;
    position: initial;
    transform: none;
 }
}
.movie-item-page__bg {
  background-image: url(https://polpoflix-stage.s3.amazonaws.com/room-bg.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: blur(20px);
  -moz-filter: blur(20px);
  -ms-filter: blur(20px);
  filter: blur(20px);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  opacity: 0.5;
}
@media screen and (max-width: 800px) {
  .movie-item-page__bg {
    display: none;
 }
}
.movie-item-page__message-input {
  max-height: 77px;
  position: absolute;
  top: 0px;
  width: 100%;
  -webkit-transition: max-height 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: max-height 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1), max-height 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 1s cubic-bezier(0.215, 0.61, 0.355, 1), max-height 0.4s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 1s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.movie-item-page__chat-bottom-container {
  width: 100%;
  padding: 0px 10px 0px 20px;
}
@media screen and (max-width: 800px) {
  .movie-item-page__chat-bottom-container {
    padding: 0px 10px 0px 10px;
 }
}
@media screen and (max-width: 800px) {
  .movie-item-page__chat-main-container {
    background-color: #141414;
 }
}
.fragment {
  z-index: -100;
}
.mobileFullScreen {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-top: 0%;
}
@media screen and (max-width: 575px) {
  .superChat {
    margin-top: 5%;
 }
}
.fullScreenIcon {
  display: none;
}
@media screen and (max-width: 575px) {
  .fullScreenIcon {
    display: block;
 }
}
.mobileFullScreenChat {
  margin-left: 10%;
  position: absolute;
  bottom: 45px;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
  height: 300px;
 /* Should be removed. Only for demonstration */
  margin-left: 3px;
}
/* Clear floats after the columns */
.roww:after {
  content: "";
  display: table;
  clear: both;
}
input:focus, select:focus, textarea:focus, button:focus, video:focus {
  outline: none;
}
.emoji {
  cursor: pointer;
  height: 30px;
  transition-property: all;
  transition-property: transform;
  transition-duration: 0.5s;
}
.player-wrapper {
  position: relative;
  padding-top: 56.25%;
 /* 720 / 1280 = 0.5625 */
}
.react-player {
  width: 600px;
  height: 600px;
}
.emoji:hover {
  transform: scale(1.3) rotate(360deg);
}
.emoji:focus {
  transform: scale(1) scale(1.3) rotate(360deg);
}
.reaction-counter {
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  background-color: #000;
  padding: 2px 5px;
  border-radius: 50px;
  border: 2px solid #fff;
  position: relative;
  right: 10px;
  top: 10px;
}
