#zmmtg-root {
  display: none;
}

.zmu-btn {
  display: none !important;
}

.full-screen-widget {
  display: block !important;
}

.participants-header__participants-pop-btn {
  display: none !important;
}

#participantSectionMenu {
  display: none !important;
}
.ax-outline-blue-important {
  display: none !important;
}

.dropdown-toggle {
  white-space: nowrap !important;
  display: inline-block !important;

  text-align: center !important;
  vertical-align: middle !important;

  background-color: transparent !important;
  border: 1px solid transparent !important;
}

.dropup .dropdown-toggle::after {
  display: none;
}

.more-button {
  display: none;
}

.participants-item__name-label {
  padding-left: 2px;
}